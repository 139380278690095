import { PureComponent } from 'react';
import Page from '../index';

export default class EN extends PureComponent<DefaultPageProps> {
  componentDidMount() {
    if (window.localStorage) {
      window.localStorage.setItem('lang', 'en');
    }
  }

  render() {
    return <Page {...this.props} prefix="en" />;
  }
}
